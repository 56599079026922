<template>
  <div class="pieChart"></div>
</template>

<script>
export default {
  name: 'linkPie',
  data() {
    return {}
  },
  methods: {
    setChart() {
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          top: '10%',
          textStyle: {
            color: '#3de7c9'
          },
          data: ['使用单位', '维保单位']
        },
        series: [
          {
            name: '环节数',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            top: '15%',
            align: 'top',
            label: {
              position: 'inner',
              color: '#3de7c9',
              fontSize: 10
            },
            labelLine: {
              show: false
            },
            data: [{ value: 23, name: '设备总数' }]
          },
          {
            name: '环节占比',
            type: 'pie',
            top: '15%',
            radius: ['40%', '50%'],
            labelLine: {
              length: 10,
              lineStyle: {
                color: '#3F3F5C'
              }
            },
            /*label: {
              fontSize: 8,
              color: '#00CCFF'
            },*/
            /* labelLine: {
               length: 15,
               length2: 10,
               lineStyle: {
                 color: '#3F3F5C'
               }
             },*/
            itemStyle: {
              color: function (params) {
                const colorList = ['#F74F64', '#00CCFF', '#315371', '#142AFE', '#9814FE', '#3de7c9', '#4444b7']
                return colorList[params.dataIndex]
              },
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              formatter: '{b}：{c}',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#3de7c9',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#8C8D8E',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#4C5058',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#fff',
                  backgroundColor: '#4C5058',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            data: [
              { value: 3, name: '故障设备' },
              { value: 23, name: '已使用' }
            ]
          }
        ]
      }
      let myChart = this.$echarts(this.$el)

      myChart.clear()
      myChart.resize()
      myChart.setOption(option)
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style lang="less" scoped>
.pieChart {
  height: 100%;
  width: 100%;
  padding: 0 20px;
}
</style>
